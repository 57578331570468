@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap');

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Comic Neue', cursive;
}

body {
  background-color: #fff;
  padding: 0 20px;
}

/* nav */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

#nav-box {
  display: flex;
  justify-content: space-between;
}

nav a {
  text-decoration: none;
  margin: 5px;
  color: #000;
}

.nav a:active,
.nav a:focus {
  color: rgb(80, 80, 212);
}

#seperator {
  margin-top: 3px;
  color: #000;
}

#home h3 {
  font-weight: bolder;
}

#home p {
  font-weight: lighter;
}

/* calculator */
#calculator {
  display: flex;
  align-items: center;
  gap: 10%;
  width: 100%;
  height: 90vh;
  margin: 0 auto;
}

#calculator h2 {
  margin-bottom: 2rem;
  font-size: 2rem;
  width: 40%;
}

#calc-box {
  width: 50%;
}

#display {
  display: flex;
  justify-content: flex-end;
  background-color: grey;
  width: 100%;
  height: 3rem;
  color: #fff;
  text-align: right;
  font-size: 2rem;
}

.calc-outer-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: repeat(5, 20%);
  height: 75%;
}

.calc-outer-container button {
  font-size: 1.4rem;
}

.spanner {
  grid-column: 1/3;
}

.coloured {
  background-color: darkorange;
}

#error {
  color: red;
}

/* quotes */
#quotes {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 40%;
  margin: 30px 0;
  padding: 10px;
  color: #000;
  background-color: #fff;
  border-radius: 0.4rem;
}

#quotes:hover {
  color: #4e4e4e;
}

#quote-loading {
  color: rgb(57, 69, 240);
}
